import React from "react";
import { Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const token = localStorage.getItem("token"); // Ottieni il token

  // Verifica se il token esiste
  if (token) {
    try {
      // Decodifica il token JWT
      const decodedToken = jwtDecode(token);

      // Verifica la scadenza del token
      if (decodedToken.exp < Date.now() / 1000) {
        // Token scaduto, reindirizza alla pagina di login
        console.log(Component);
        console.log(rest);
        return <Navigate to="/iPanel/" />;
      }

      // Token valido, consenti l'accesso alla pagina
      console.log(Component);
      console.log(rest);
      return <Component {...rest} />;
    } catch (error) {
      // Errore durante la decodifica o la verifica del token, reindirizza alla pagina di login
      console.error("Errore nella verifica del token:", error);
      console.log(Component);
      console.log(rest);
      return <Navigate to="/iPanel/" />;
    }
  } else {
    // Se il token non esiste, reindirizza alla pagina di login
    console.log(Component);
    return <Navigate to="/iPanel/" />;
  }
};

export default PrivateRoute;
