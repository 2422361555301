import React from "react";
import NavBar from "./partials/NavBar";
const Cookie = () => {
  return (
    <>
      <NavBar />
      <div className="container">
        <div className="row justify-content-md-center mb_30">
          <div className="col-md-8 col-xs-12 mb_30">
            <h1>Cookie Policy</h1>
            <p>
              This website (
              <a href="https://meetnewpartners.com">
                https://meetnewpartners.com
              </a>
              ) does not use cookies, except for technical ones that are
              strictly necessary
            </p>
            
            <div class="ccm-cookie-declaration" data-lang="en_EN"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cookie;
