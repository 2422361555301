import React from "react";
import NavBar from "./partials/NavBar";
const Media = () => {
  return (
    <>
      <NavBar />
      <div className="container mt-3">
        <div className="row">
          <div className="col-12 mt-3 mb-3 p-3">
            <h1>Umbria Media Gallery</h1>
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="card mb-3" style={{ maxWidth: "540px" }}>
                  <div className="row g-0">
                    <div className="col-md-12">
                      {" "}
                      {/* Occupiamo tutta la larghezza della card */}
                      <div className="ratio ratio-16x9">
                        {" "}
                        {/* Manteniamo le proporzioni del video */}
                        <iframe
                          src={`https://www.youtube.com/embed/up4Mh3wjZ-s`}
                          title="Tortelloni with carbonara cream'"
                          allowFullScreen
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      {" "}
                      {/* Occupiamo tutta la larghezza della card */}
                      <div className="card-body">
                        <h6 className="card-title">
                          <i class="fa-solid fa-circle-info"></i>{" "}
                          <a
                            href="/media-detail/up4Mh3wjZ-s"
                            title="Tortelloni with Carbonara Cream recipe detail"
                          >
                            Tortelloni with Carbonara cream
                          </a>
                        </h6>
                        {/* <p className="card-text mb-1">Assisi (PG)</p>
                        <p className="card-text">
                          <small className="text-body-secondary">
                            Sector: <i>Bespoke travel experiences</i>
                          </small>
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="card mb-3" style={{ maxWidth: "540px" }}>
                  <div className="row g-0">
                    <div className="col-md-12">
                      {" "}
                      {/* Occupiamo tutta la larghezza della card */}
                      <div className="ratio ratio-16x9">
                        {" "}
                        {/* Manteniamo le proporzioni del video */}
                        <iframe
                          src={`https://www.youtube.com/embed/mL3_mssQxMQ`}
                          title="Garganelli with Bolognese Ragù'"
                          allowFullScreen
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      {" "}
                      {/* Occupiamo tutta la larghezza della card */}
                      <div className="card-body">
                        <h6 className="card-title">
                          <i class="fa-solid fa-circle-info"></i>{" "}
                          <a
                            href="/media-detail/mL3_mssQxMQ"
                            title="Garganelli with Bolognese Ragù detail"
                          >
                            Garganelli with Bolognese Ragù
                          </a>
                        </h6>
                        {/* <p className="card-text mb-1">All Italy</p>
                        <p className="card-text">
                          <small className="text-body-secondary">
                            <i>Recipes</i>
                          </small>
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="card mb-3" style={{ maxWidth: "540px" }}>
                  <div className="row g-0">
                    <div className="col-md-12">
                      {" "}
                      {/* Occupiamo tutta la larghezza della card */}
                      <div className="ratio ratio-16x9">
                        {" "}
                        {/* Manteniamo le proporzioni del video */}
                        <iframe
                          src={`https://www.youtube.com/embed/soLy6U4JdWQ`}
                          title="Strangozzi with truffle'"
                          allowFullScreen
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      {" "}
                      {/* Occupiamo tutta la larghezza della card */}
                      <div className="card-body">
                        <h6 className="card-title">
                          <i class="fa-solid fa-circle-info"></i>{" "}
                          <a
                            href="/media-detail/soLy6U4JdWQ"
                            title="Strangozzi with truffle recipe detail"
                          >
                            Strangozzi with truffle
                          </a>
                        </h6>
                        {/* <p className="card-text mb-1">Assisi (PG)</p>
                        <p className="card-text">
                          <small className="text-body-secondary">
                            Sector: <i>Bespoke travel experiences</i>
                          </small>
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="card mb-3" style={{ maxWidth: "540px" }}>
                  <div className="row g-0">
                    <div className="col-md-12">
                      {" "}
                      {/* Occupiamo tutta la larghezza della card */}
                      <div className="ratio ratio-16x9">
                        {" "}
                        {/* Manteniamo le proporzioni del video */}
                        <iframe
                          src={`https://www.youtube.com/embed/KowABnohDd8`}
                          title="Tagliatelle with Bolognese Ragù'"
                          allowFullScreen
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      {" "}
                      {/* Occupiamo tutta la larghezza della card */}
                      <div className="card-body">
                        <h6 className="card-title">
                          <i class="fa-solid fa-circle-info"></i>{" "}
                          <a
                            href="/media-detail/KowABnohDd8"
                            title="Tagliatelle with Bolognese Ragù detail"
                          >
                            Tagliatelle with Bolognese Ragù
                          </a>
                        </h6>
                        {/* <p className="card-text mb-1">All Italy</p>
                        <p className="card-text">
                          <small className="text-body-secondary">
                            <i>Recipes</i>
                          </small>
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="card mb-3" style={{ maxWidth: "540px" }}>
                  <div className="row g-0">
                    <div className="col-md-12">
                      {" "}
                      {/* Occupiamo tutta la larghezza della card */}
                      <div className="ratio ratio-16x9">
                        {" "}
                        {/* Manteniamo le proporzioni del video */}
                        <iframe
                          src={`https://www.youtube.com/embed/ZXJ8N0YUuvA`}
                          title="Tortelloni pumpkin-filled with butter and sage'"
                          allowFullScreen
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      {" "}
                      {/* Occupiamo tutta la larghezza della card */}
                      <div className="card-body">
                        <h6 className="card-title">
                          <i class="fa-solid fa-circle-info"></i>{" "}
                          <a
                            href="/media-detail/ZXJ8N0YUuvA"
                            title="Tortelloni pumpkin-filled with butter and sage detail"
                          >
                            Tortelloni pumpkin-filled with butter and sage
                          </a>
                        </h6>
                        {/* <p className="card-text mb-1">Assisi (PG)</p>
                        <p className="card-text">
                          <small className="text-body-secondary">
                            Sector: <i>Bespoke travel experiences</i>
                          </small>
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="card mb-3" style={{ maxWidth: "540px" }}>
                  <div className="row g-0">
                    <div className="col-md-12">
                      {" "}
                      {/* Occupiamo tutta la larghezza della card */}
                      <div className="ratio ratio-16x9">
                        {" "}
                        {/* Manteniamo le proporzioni del video */}
                        <iframe
                          src={`https://www.youtube.com/embed/-0thq895HVQ`}
                          title="Tortellini with parmesan cream"
                          allowFullScreen
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      {" "}
                      {/* Occupiamo tutta la larghezza della card */}
                      <div className="card-body">
                        <h6 className="card-title">
                          <i class="fa-solid fa-circle-info"></i>{" "}
                          <a
                            href="/media-detail/-0thq895HVQ"
                            title="Tortellini with parmesan cream"
                          >
                            Tortellini with Parmesan Cream
                          </a>
                        </h6>
                        {/* <p className="card-text mb-1">All Italy</p>
                        <p className="card-text">
                          <small className="text-body-secondary">
                            <i>Recipes</i>
                          </small>
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Media;
