import React from "react";

const Carousel = () => {
  
  return (
    <div
      id="carouselExampleControls"
      className="carousel slide"
      data-bs-ride="carousel"
    >
      <div className="carousel-inner">
        <div className="carousel-item active align-middle">
          <img
            src="/img/slides/umbria-italy.jpg"
            className="d-block w-100"
            alt="Welcome in Umbria"
          />
          <div className="carousel-caption d-md-block">
            <h1>
              Meet New Partners:
            </h1>
            <h3>
              connecting Umbria's excellence to the world
            </h3>
          </div>
        </div>
        <div className="carousel-item">
          <img
            src="/img/slides/fioritura-castelluccio-di-norcia.jpg"
            className="d-block w-100"
            alt="Lentil blooming in Castelluccio di Norcia"
          />
          <div className="carousel-caption d-md-block">
            <h1>Food & Beverage</h1>
            <h2>
              Such as Lentil blooming in Castelluccio di Norcia or Truffles, Oil
              and Meat traditions.
            </h2>
          </div>
        </div>
        <div className="carousel-item">
          <img
            src="/img/slides/luxury-real-estate.jpg"
            className="d-block w-100"
            alt="Luxury real estate in Umbria"
          />
          <div className="carousel-caption d-md-block">
            <h1>Luxury Real Estate</h1>
            <h2>
              Investments, and exclusive stays in magical places surrounded by
              nature
            </h2>
          </div>
        </div>
        <div className="carousel-item">
          <img
            src="/img/slides/assisi.jpg"
            className="d-block w-100"
            alt="Assisi: Basilica of St. Francis"
          />
          <div className="carousel-caption d-md-block">
            <h1>Travel & Tourism</h1>
            <h2>Culture, Christianity, History, Arts.</h2>
          </div>
        </div>
        <div className="carousel-item">
          <img
            src="/img/slides/ceramiche-deruta.jpg"
            className="d-block w-100"
            alt="Ceramics of Deruta"
          />
          <div className="carousel-caption d-md-block">
            <h1>Handcraft</h1>
            <h2>
              Ancient crafts done with the heart, unique handmade products
            </h2>
          </div>
        </div>
      </div>
      <a
        className="carousel-control-prev"
        href="#carouselExampleControls"
        role="button"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        {/* <span className="sr-only">Previous</span> */}
      </a>
      <a
        className="carousel-control-next"
        href="#carouselExampleControls"
        role="button"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        {/* <span className="sr-only">Next</span> */}
      </a>
    </div>
  );
};

export default Carousel;