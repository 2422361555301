import React from "react";
import { useNavigate } from "react-router-dom";
import NavBar from "./partials/NavBar";
import Carousel from "./partials/Carousel";
const Home = () => {
    const navigate = useNavigate();
    const handleContact = () => {
      navigate('/contact');
    };
    
    const handleRegister = () => {
      navigate('/join-us');
    };
    return (
      <>
        <NavBar />
        <Carousel />

        <div className="container mt-3">
          <div className="row">
            <div className="col-12 mt-3 mb-3 p-3">
              <div className="card">
                <img
                  src="/img/BNI-global-convention-2024.jpg"
                  className="card-img-top"
                  alt="BNI Umbria to Hawaii"
                ></img>
                <div className="card-body">
                  {/* <h5 className="card-title">Food & Beverage</h5>
                  <h6 className="card-subtitle mb-2 text-body-secondary">
                    Typical Local Umbrian Products
                  </h6> */}
                  <p className="card-text">
                    Welcome to a journey where tradition meets innovation, where
                    the heart of Italy opens its doors to global partnerships.
                    Meet New Partners proudly presents the enchanting region of
                    Umbria at the prestigious Global Convention in Hawaii,
                    offering a unique gateway to discover and collaborate with
                    the finest Umbrian enterprises.
                  </p>
                  <h4>Unveiling Umbria's excellence</h4>
                  <p>
                    Nestled in the verdant heart of Italy, Umbria is a treasure
                    trove of{" "}
                    <b>
                      culinary mastery, luxurious living, unforgettable
                      experiences, and unparalleled craftsmanship
                    </b>
                    . Our mission is to bridge Umbria's exceptional offerings
                    with global markets, fostering partnerships that transcend
                    borders and time.
                  </p>
                  <p>
                    Imagine savoring the rich flavors of award-winning olive
                    oils and rare truffles, or sipping exquisite wines crafted
                    in sun-drenched vineyards. Picture yourself exploring
                    restored medieval castles and charming countryside villas
                    that blend centuries of history with modern luxury. Envision
                    immersing in bespoke travel experiences that reveal the
                    hidden gems of Umbrian culture and natural beauty. Feel the
                    softness of premium cashmere and admire the intricate
                    designs of handcrafted ceramics, each piece a testament to
                    Umbria's artistic heritage.
                  </p>
                  <p>
                    These are not just products or services; they are the
                    essence of Umbrian excellence, carefully curated to bring
                    the best of Italy to the world stage.
                  </p>
                  <h4>Your gateway to Italian excellence</h4>
                  <p>
                    At <strong>Meet New Partners</strong>, we are more than just
                    a showcase; we are your dedicated guides to the world of
                    Umbrian excellence. Our team of passionate experts is here
                    to help you discover unique opportunities, forge meaningful
                    partnerships, and bring a piece of Italian magic to your
                    business or life.
                  </p>
                  <p>
                    Whether you're a gourmet food retailer seeking authentic
                    flavors, a luxury real estate investor looking for hidden
                    gems, a travel agency aiming to offer exclusive experiences,
                    or simply an enthusiast of Italian culture and
                    craftsmanship, Meet New Partners is{" "}
                    <b>your key to unlocking the treasures of Umbria</b>.
                  </p>
                  <p>
                    Join us at <b>Booth Nr.80</b> and embark on a journey
                    through the flavors, beauty, and traditions of Umbria.
                    Together, let's create partnerships that celebrate the best
                    of Italy and resonate with discerning audiences worldwide.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-3 mb-3" id="excellence">
            <h1 className="text-center">Our Sectors of Excellence</h1>
            <div className="col-md-3 col-sm-6 col-xs-12 p-3">
              <div className="card fullHeight d-flex flex-column">
                <img
                  src="/img/slides/tartufi-umbria.jpg"
                  className="card-img-top"
                  alt="Truffles"
                ></img>
                <div className="card-body flex-grow-1">
                  <h5 className="card-title">Food & Beverage</h5>
                  <h6 className="card-subtitle mb-2 text-body-secondary">
                    Umbria's culinary tradition is a journey through flavors,
                    aromas, and passion.
                  </h6>
                  <p className="card-text">
                    Our food and beverage offerings showcase the region's
                    finest:
                  </p>
                  <ul>
                    <li>Artisanal olive oils from ancient groves</li>
                    <li>Rare truffles and truffle-infused delicacies</li>
                    <li>Award-winning wines from boutique vineyards</li>
                    <li>Handcrafted pasta and traditional sweets</li>
                  </ul>
                  <p className="card-text">
                    Each product tells a story of dedication, tradition, and
                    uncompromising quality.
                    <br />
                  </p>
                </div>
                <button
                  className="btn btn-secondary m-2"
                  onClick={handleRegister}
                >
                  Are you interested in?
                </button>
              </div>
            </div>

            <div className="col-md-3 col-sm-6 col-xs-12 p-3">
              <div className="card fullHeight d-flex flex-column">
                <img
                  src="/img/slides/luxury-real-estate.jpg"
                  className="card-img-top"
                  alt="Luxury Real-estate in Italy"
                ></img>
                <div className="card-body flex-grow-1">
                  <h5 className="card-title">Luxury Real Estate</h5>
                  <h6 className="card-subtitle mb-2 text-body-secondary">
                    Experience the charm of Umbrian living with our curated
                    selection of properties:
                  </h6>
                  <p className="card-text"></p>
                  <ul>
                    <li>Restored medieval castles with modern amenities</li>
                    <li>Rustic farmhouses surrounded by rolling hills</li>
                    <li>Elegant villas with panoramic views</li>
                    <li>Charming apartments in historic town centers</li>
                  </ul>
                  <br />
                  <p className="card-text">
                    Our real estate offerings combine Umbria's rich history with
                    contemporary luxury.
                  </p>
                </div>
                <button
                  className="btn btn-secondary m-2"
                  onClick={handleRegister}
                >
                  Want to know more?
                </button>
              </div>
            </div>

            <div className="col-md-3 col-sm-6 col-xs-12 p-3">
              <div className="card fullHeight d-flex flex-column">
                <img
                  src="/img/slides/assisi.jpg"
                  className="card-img-top"
                  alt="Tourism & Travel to Umbria"
                ></img>
                <div className="card-body flex-grow-1">
                  <h5 className="card-title">Bespoke travel experiences</h5>
                  <h6 className="card-subtitle mb-2 text-body-secondary">
                    Discover Umbria through immersive, tailor-made journeys:
                  </h6>
                  <p className="card-text"></p>
                  <ul>
                    <li>Exclusive wine tasting tours in hidden cellars</li>
                    <li>Hands-on cooking classes with renowned chefs</li>
                    <li>Art and history expeditions to UNESCO sites</li>
                    <li>Wellness retreats in pristine natural settings</li>
                  </ul>
                  <br />
                  <p className="card-text">
                    We craft unforgettable experiences that capture the essence
                    of Umbrian life.
                  </p>
                </div>
                <button
                  className="btn btn-secondary m-2"
                  onClick={handleRegister}
                >
                  Come to Umbria!
                </button>
              </div>
            </div>

            <div className="col-md-3 col-sm-6 col-xs-12 p-3">
              <div className="card fullHeight d-flex flex-column">
                <img
                  src="/img/slides/ceramiche-deruta.jpg"
                  className="card-img-top"
                  alt="Italian manufactured goods"
                ></img>
                <div className="card-body flex-grow-1">
                  <h5 className="card-title">Artisanal crafts & art</h5>
                  <h6 className="card-subtitle mb-2 text-body-secondary">
                    Umbria's craftsmanship is a testament to centuries of skill
                    and passion:
                  </h6>
                  <p className="card-text"></p>
                  <ul>
                    <li>Hand-painted ceramics from master artisans</li>
                    <li>Exquisite textiles woven on traditional looms</li>
                    <li>Bespoke furniture crafted from local woods</li>
                    <li>Modern art & fine craftsmanship</li>
                  </ul>
                  <p className="card-text">
                    Our artisanal products blend time-honored techniques with
                    contemporary design, bringing a piece of Umbria to homes
                    worldwide.
                  </p>
                </div>
                <button
                  className="btn btn-secondary m-2"
                  onClick={handleRegister}
                >
                  Discover now
                </button>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 mt-3 mb-3">
              <div className="card">
                <div className="card-body">
                  <h1>Connect with us to grow together</h1>
                  <p>
                    We're here to transform opportunities into tangible success.
                    If you're interested in exploring international business
                    possibilities or joining our network of excellence, reach
                    out to us:
                  </p>
                  <p>
                    <strong>Meet New Partners</strong>
                    <br />
                    Email:{" "}
                    <a href="mailto:info@meetnewpartners.com">
                      info@meetnewpartners.com
                    </a>
                    <br />
                    Phone: <a href="tel:00393336297478">+39 333 6297478</a>
                  </p>
                  <p>
                    <strong>Meet Us at the BNI Global Convention</strong>
                    <br />
                    Booth Nr.80
                    <br />
                    Hilton Hawaiian Village
                    <br />
                    Waikiki Beach Resort
                  </p>
                  <p className="mb_30">
                    We're ready to bring your business to the world stage!
                    <br />
                    &nbsp;
                    <button
                      onClick={handleContact}
                      className="btn btn-secondary"
                      style={{ float: "right" }}
                    >
                      Contact form{" "}
                      <i class="fa-solid fa-circle-chevron-right"></i>
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default Home;