import React from "react";
import { useParams } from "react-router-dom"; // Importa useParams
import NavBar from "./partials/NavBar";
const MediaDetail = () => {
    const videoId = useParams();
    console.log(videoId);
  return (
    <>
      <NavBar />
      <div className="container mt-3">
        <div className="row">
          <div className="col-12 mt-3 mb-3 p-3">
            <a className="text-right" href="javascript:history.go(-1);">
              <i className="fa-solid fa-circle-chevron-left"></i> Go back
            </a>
            <h1>Umbria Media Gallery</h1>
            <div className="card">
              <div className="ratio ratio-16x9">
                <iframe
                  src={`https://www.youtube.com/embed/${videoId.id}`}
                  title="meetnewpartners.com"
                  allowFullScreen
                  allow="autoplay;"
                />
              </div>
              {/* <div className="card-body">
                <h5 className="card-title">Original Carbonara recipe</h5>
                <p className="card-text">
                  Here’s the proper way to make carbonara - before people
                  started cheating by adding cream. It’s made using eggs and
                  parmigiana reggiano which, when vigorously mixed with starchy
                  pasta cooking water, forms a beautiful creamy sauce. You’ll
                  love how creamy and rich it tastes but you don’t get that
                  weighed down feeling like you do after eating pastas made with
                  loads of cream!
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MediaDetail;
