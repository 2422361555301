import React from "react";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer mt-5">
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-xs-8 text-center mb-3">
            <a href="/privacy">
              <small>Privacy Policy</small>
            </a>{" "}
            |{" "}
            <a href="/cookie">
              <small>Cookie Policy</small>
            </a>
          </div>
        </div>
        <div className="row justify-content-md-center">
          <div className="col-xs-8 text-center">
            <a
              href="https://www.cre-azione.it/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/img/FD.png"
                alt="FD s.r.l."
                style={{ height: "5vh", width: "auto" }}
              />
            </a>{" "}
            &nbsp;
            <a
              href="https://www.mediamarketer.it/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/img/MediaMarketer.png"
                alt="Media Marketer"
                style={{ height: "5vh", width: "auto" }}
              />
            </a>
          </div>
        </div>

        <div className="row justify-content-md-center">
          <div className="col-md-6 col-xs-12 copyright">
            <small style={{ fontSize: "0.66rem" }}>
              &copy; {currentYear === 2024 ? "2024" : `2024 - ${currentYear}`}{" "}
              Media Marketer s.r.l. - All rights reserved - VAT # IT-02372750444
            </small>
          </div>
          <div className="col-md-6 col-xs-12 powered">
            <small style={{ fontSize: "0.66rem" }}>
              Powered & designed by{" "}
              <a
                href="https://www.stefanocoggiola.com"
                style={{ fontWeight: "400" }}
                target="_blank"
                rel="noreferrer"
              >
                Stefano Coggiola
              </a>
            </small>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
