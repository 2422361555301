import React from "react";
import { useNavigate } from "react-router-dom";
const NavBar = ({ onExcellenceClick }) => {
  const navigate = useNavigate();  
  const handleHome = () => {
    navigate("/");
  };
  const handleAbout = () => {
    navigate("/about");
  };
  const handleContact = () => {
    navigate("/contact");
  };
  const handleRegister = () => {
    navigate("/join-us");
  };
  const handleMedia = () => {
    navigate("/media");
  };
  return (
    <nav className="navbar navbar-expand-lg navbar-dark">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
        <img
          src="/img/logo-meetnewpartners-com.png"
          style={{
            width: "15rem",
            height: "auto",
            minWidth: "40px!important",
            marginRight: "4vw",
            marginLeft: "2vw",
          }}
          alt="meetnewpartners.com"
        /></a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav">
            <button className="btn btn-secondary m-2" onClick={handleHome}>
              Home
            </button>
            <button className="btn btn-secondary m-2" onClick={handleAbout}>
              About us
            </button>
            <a href="/#excellence" className="btn btn-secondary m-2" style={{color: '#FFF'}}>
              Sectors of Excellence
            </a>
            <button className="btn btn-secondary m-2" onClick={handleRegister}>
              Join us
            </button>
            <button className="btn btn-secondary m-2" onClick={handleMedia}>
              Media
            </button>
            <button className="btn btn-secondary m-2" onClick={handleContact}>
              Contact
            </button>
          </div>
        </div>
      </div>
    </nav>

    // <nav className="navbar navbar-expand-lg navbar-dark">
    //   <a className="navbar-brand" href="/">
    //     <img
    //       src="/img/logo-meetnewpartners-com.png"
    //       style={{
    //         width: "15rem",
    //         height: "auto",
    //         minWidth: "40px!important",
    //         marginRight: "4vw",
    //         marginLeft: "2vw",
    //       }}
    //       alt="meetnewpartners.com"
    //     />
    //   </a>
    //   <button
    //     className="navbar-toggler"
    //     type="button"
    //     data-toggle="collapse"
    //     data-target="#navbarNavDropdown"
    //     aria-controls="navbarNavDropdown"
    //     aria-expanded="false"
    //     aria-label="Toggle navigation"
    //   >
    //     <span className="navbar-toggler-icon" style={{ color: "white" }}></span>
    //   </button>
    //   <div className="collapse navbar-collapse" id="navbarNavDropdown">
    //     <ul className="navbar-nav" style={{ textAlign: "right" }}>
    //       <li className="nav-item">
    //         {/* <a className="nav-link text-light" href="/"> */}
    //         <button className="btn btn-secondary m-2" onClick={handleHome}>
    //           Home
    //         </button>
    //         {/* </a> */}
    //       </li>
    //       <li className="nav-item">
    //         {/* <a className="nav-link text-light" href="/about"> */}
    //         <button className="btn btn-secondary m-2" onClick={handleAbout}>
    //           About us
    //         </button>
    //         {/* </a> */}
    //       </li>
    //       <li className="nav-item">
    //         <a href="/#excellence" className="btn btn-secondary m-2" style={{color: '#FFF'}}>
    //           Sectors of Excellence
    //         </a>
    //       </li>
    //       <li className="nav-item">
    //         <button className="btn btn-secondary m-2" onClick={handleRegister}>
    //           Join us
    //         </button>
    //       </li>
    //       <li className="nav-item">
    //         <button className="btn btn-secondary m-2" onClick={handleMedia}>
    //           Media
    //         </button>
    //       </li>

    //       <li className="nav-item">
    //         <button className="btn btn-secondary m-2" onClick={handleContact}>
    //           Contact
    //         </button>
    //       </li>
    //     </ul>
    //   </div>
    // </nav>
  );
};

export default NavBar;
