import React from "react";
import NavBar from "./partials/NavBar";
const Privacy = () => {
  return (
    <>
      <NavBar />
      <div className="container">
        <div className="row justify-content-md-center mb_30">
          <div className="col-md-8 col-xs-12 mb_30">
            <div className="card">
              <img
                src="/img/privacy.jpg"
                className="card-img-top"
                alt="Privacy policy"
              ></img>
              <div className="card-body">
                <h1>Information on the processing of personal data</h1>
                <p>
                  Pursuant to Articles 13 and 14 of Regulation 2016/679/EU
                  (hereinafter referred to as ‘GDPR’) MEDIA MARKETER S.R.L. in
                  the person of its legal representative. (hereinafter referred
                  to as ‘Data Controller’) with registered office in SAN
                  BENEDETTO DEL TRONTO (AP), CORSO MAZZINI 267/A - 63074, in its
                  capacity as ‘Data Controller’, informs you that your personal
                  data collected for the purposes of the conclusion of the
                  contract with the Customer and/or within the scope of the
                  execution and/or stipulation of the same will be processed in
                  compliance with the aforementioned regulations, in order to
                  guarantee the rights, fundamental freedoms and dignity of
                  natural persons, with particular reference to confidentiality
                  and personal identity. We inform you that if the activities
                  provided to you involve the processing of personal data of
                  third parties in your possession, it will be your
                  responsibility to ensure that you have complied with the
                  provisions of the law with regard to the data subjects in
                  order to make their processing by us legitimate.
                </p>
                <h6>
                  Origin, purpose, legal basis and nature of data processed
                </h6>
                <p>
                  The processing of your personal data, directly supplied by
                  you, is carried out by MEDIA MARKETER S.R.L. for the purpose
                  of the conclusion of the contract with the Customer and/or in
                  the context of the execution and/or conclusion of the same.
                </p>
                <p>
                  It is also possible that personal data of third parties
                  communicated by the Customer to the Company may be processed.
                  With respect to this hypothesis, the Customer acts as
                  autonomous data controller and assumes the consequent legal
                  obligations and responsibilities, holding the Company harmless
                  with respect to any dispute, claim and/or request for
                  compensation for damages from processing that may be received
                  by the Company from third parties.
                </p>
                <p>
                  In compliance with current legislation on the protection of
                  personal data and without the need for specific consent from
                  the Data Subject, the Data shall be stored, collected and
                  processed by the Company for the following purposes:
                </p>
                
                  <ol type="a)">
                    <li>
                      fulfilment of contractual obligations, execution and/or
                      conclusion of the contract with the customer and/or
                      management of any pre-contractual measures;
                    </li>
                    <li>
                      fulfilment of any regulatory obligations, tax and fiscal
                      provisions arising from the conduct of business and
                      obligations related to administrative-accounting
                      activities;
                    </li>
                    <li>
                      sending, directly or through third party providers of
                      marketing and communication services, newsletters and
                      communications for direct marketing purposes via email,
                      sms, mms, push notifications, fax, paper mail, telephone
                      with operator, in relation to products provided by the
                      other companies pursuant to Art. 130 c. 1 and 2 of
                      Legislative Decree 196/03 (hereinafter referred to as the
                      ‘Code’);
                    </li>
                    <li>
                      communication of the Data to third party companies for the
                      purpose of sending newsletters and communications for
                      marketing purposes by email, sms, mms, push notifications,
                      fax, paper mail, telephone with operator pursuant to art.
                      130 c. 1 and 2 of the Code.
                    </li>
                  </ol>
                
                <p>
                  The legal bases of the processing for the purposes a) and b)
                  above are Articles 6.1.b) and 6.1.c) of the Regulation.
                </p>
                <p>
                  The provision of Data for the aforementioned purposes is
                  optional, but failure to provide it and refusal to provide it
                  would make it impossible for the Company to execute and/or
                  enter into the contract and provide the services requested by
                  the same.
                </p>
                <p>
                  The legal basis for the processing of personal data for the
                  purposes c) and d) is Article 6.1.a) of the GDPR as the
                  processing is based on consent; it should be noted that the
                  Data Controller may only collect one consent for the marketing
                  purposes described herein, pursuant to the General Provision
                  of the Guarantor for the protection of personal data
                  ‘Guidelines on promotional activities and the fight against
                  spam’ of 4 July 2013. The provision of consent to the use of
                  data for marketing purposes is optional and should the data
                  subject wish to object to the processing of the Data for
                  marketing purposes carried out by the means indicated herein,
                  as well as to withdraw the consent given; he/she may do so at
                  any time without any consequences (except for the fact that
                  he/she will no longer receive marketing communications) by
                  following the instructions in the ‘Rights of the Data Subject’
                  section of this Policy.
                </p>
                <p>
                  Lastly, please note that for the processing carried out for
                  the purpose of sending its own advertising material or direct
                  sales or for carrying out its own market research or
                  commercial communications in relation to products or services
                  similar to those used by the Customer, the Company may use
                  e-mail or personal data addresses pursuant to and within the
                  limits allowed by Article 130, paragraph 4 of the Code and by
                  the provision of the Guarantor Authority for the protection of
                  personal data of 19 June 2008 even in the absence of explicit
                  consent. The legal basis for the processing of data for this
                  purpose is Art. 6, paragraph 1, letter f) of the GDPR, without
                  prejudice to the possibility of opposing such processing at
                  any time, by following the instructions in the ‘Rights of the
                  Data Subject’ section of this Information Notice.
                </p>
                <h6>Comunicazione</h6>
                <p>
                  The data may be communicated to third parties appointed as
                  data processors pursuant to Article 28 of the GDPR and in
                  particular to banking institutions, companies active in the
                  insurance field, suppliers of services strictly necessary for
                  the performance of business activities, or consultants of the
                  company, where this proves necessary for fiscal,
                  administrative or contractual reasons or for requirements
                  protected by current regulations.
                </p>
                <p>
                  Your personal data, or the personal data of third parties in
                  your possession, may also be communicated to external
                  companies, identified on a case-by-case basis, to which MEDIA
                  MARKETER S.R.L. entrusts the execution of obligations arising
                  from the assignment received, to which only the data necessary
                  for the activities requested of them will be transmitted. All
                  employees, consultants, interims and/or any other ‘natural
                  person’ who carry out their activities on the basis of
                  instructions received from MEDIA MARKETER S.R.L., in
                  accordance with Article 29 of the GDPR, are appointed ‘Persons
                  in charge of the processing’ (hereinafter also ‘Persons in
                  charge’). MEDIA MARKETER S.R.L. imparts adequate operating
                  instructions to the persons in charge or to the persons in
                  charge, who may be designated, with particular reference to
                  the adoption of and compliance with security measures, in
                  order to be able to guarantee the confidentiality and security
                  of the data. With particular reference to the aspects of
                  personal data protection, the Customer is invited, pursuant to
                  Article 33 of the GDPR, to notify MEDIA MARKETER S.R.L. of any
                  circumstances or events from which a potential ‘personal data
                  violation (data breach)’ may derive, in order to allow an
                  immediate evaluation and the adoption of any actions aimed at
                  contrasting such an event, by sending a communication to MEDIA
                  MARKETER S.R.L. at the addresses indicated below.
                </p>
                <p>The Data will not be disseminated.</p>
                <p>
                  This is without prejudice to MEDIA MARKETER S.R.L.'s
                  obligation to communicate the data to Public Authorities upon
                  specific request.
                </p>
                <h6>Transfer abroad</h6>
                <p>
                  The transfer abroad of your personal data may take place if it
                  is necessary for the management of the assignment received.
                  The processing of information and data that may be
                  communicated to these parties will require the equivalent
                  levels of protection adopted for the processing of the
                  personal data of its own employees. In any case, only the data
                  necessary for the pursuit of the intended purposes will be
                  communicated, and the regulatory tools provided for in Chapter
                  V of the GDPR will be applied.
                </p>
                <h6>Modalities, processing logics and retention times</h6>
                <p>
                  Your data is collected and recorded lawfully and fairly for
                  the above-mentioned purposes in accordance with the principles
                  and requirements of Article 5 c 1 of the GDPR.
                </p>
                <p>
                  The processing of personal data is carried out by means of
                  manual, computerised and telematic tools, with logic strictly
                  related to the purposes themselves and, in any case, in such a
                  way as to guarantee security and confidentiality.
                </p>
                <p>
                  Personal Data will be processed by MEDIA MARKETER S.R.L. for
                  the entire duration of the assignment and also thereafter for
                  the purpose of asserting or protecting its rights or for
                  administrative purposes and/or to execute obligations arising
                  from the applicable pro tempore regulatory and legislative
                  framework and in compliance with specific legal obligations on
                  data retention.
                </p>
                <h6>Rights of the Data Subject</h6>
                <p>
                  In accordance with, within the limits and under the conditions
                  provided for by the data protection legislation concerning the
                  exercise of the rights of the Data Subject 1 with regard to
                  the processing operations covered by this Information Notice,
                  as a Data Subject you have the right to request confirmation
                  as to whether or not personal data relating to you is being
                  processed, to access the personal data relating to you and in
                  relation to such data you have the right to request its
                  rectification, erasure notification of rectification and
                  cancellation to those to whom the data may have been
                  transmitted by our organisation, restriction of processing in
                  the cases provided for by the law, portability of personal
                  data - provided by you - in the cases indicated by the law, to
                  object to the processing of your data and, specifically, you
                  have the right to object to decisions concerning you if they
                  are based solely on automated processing of your data,
                  including profiling. Should you consider that the processing
                  operations concerning you violate the rules of the GDPR, you
                  have the right to lodge a complaint with the Garante pursuant
                  to Article 77 of the GDPR.
                </p>
                <p>
                  If you wish to request further information on the processing
                  of your personal data or to exercise your rights, please
                  contact PERINI LEONARDO in writing.
                </p>

                <h6>Data Controller and Processor</h6>
                <p>
                  The Data Controller, pursuant to Article 4 of the GDPR, is
                  MEDIA MARKETER S.R.L., in the person of its legal
                  representative, with registered office in CORSO MAZZINI 267/A
                  - 63074, SAN BENEDETTO DEL TRONTO (AP) VAT #: IT-02372750444 -
                  Tax Code: 02372750444. The full list of Data Processors is
                  available at the company's registered office.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Privacy;
