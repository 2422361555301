import React, { useState } from "react";
import Header from "./partials/Header";
const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);

    try {
      const response = await   
 fetch('/api/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        // Login effettuato con successo, reindirizza l'utente
        const data = await response.json();
        localStorage.setItem('token', data.token); // Salva il token in localStorage
        window.location.href = '/iPanel/utenti-registrati'; // Reindirizza alla pagina dei registrati
      } else {
        const data = await response.json();
        setError(data.message || 'Credenziali non valide');
      }
    } catch (error) {
      setError('Errore durante l\'accesso');
    }
  };

  return (
    <>
      <Header />
      <div className="container mt-3">
        <div className="row justify-content-md-center">
          <div className="col-md-8 col-sm-12 col-xs-12 mt-3 mb-3 p-3">
            <div className="card">
              <div className="card-body">
                <h1 className="mb-5 text-center">Login area riservata</h1>
                {error && <div className="error">{error}</div>}
                <form className="was-validated" onSubmit={handleSubmit}>
                  <div className="row justify-content-md-center">
                    <div className="col-md-5 col-xs-5 mb-0 text-center">
                      <label htmlFor="password">Nome utente</label>
                    </div>
                    <div className="col-md-7 col-xs-7 mb-3 text-center">
                      <input
                        type="text"
                        id="username"
                        value={username}
                        className="form-control"
                        onChange={(e) => setUsername(e.target.value)}
                        required
                        aria-required="true"
                      />
                    </div>
                  </div>
                  <div className="row justify-content-md-center">
                    <div className="col-md-5 col-xs-5 mb-0 text-center">
                      <label htmlFor="password">Password</label>
                    </div>
                    <div className="col-md-7 col-xs-7 mb-5 text-center">
                      <input
                        type="password"
                        id="password"
                        value={password}
                        className="form-control"
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        aria-required="true"
                      />
                    </div>
                  </div>
                  <div className="row justify-content-md-center">
                    <button type="submit" className="btn btn-secondary btn-lg">
                      ACCEDI
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
