import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Registration from "./components/Registration";
import Contact from "./components/Contact";
import About from "./components/About";
import Media from "./components/Media";
import MediaDetail from "./components/MediaDetail";
import Privacy from "./components/Privacy";
import Cookie from "./components/Cookie";
import UserForm from "./components/UserForm";
import Footer from "./components/partials/Footer";
import Login from "./components/Login"
import UtentiRegistrati from "./components/UtentiRegistrati";
import PrivateRoute from "./components/PrivateRoute";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/subscription" element={<UserForm />} />
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/join-us" element={<Registration />} />
        <Route path="/about" element={<About />} />
        <Route path="/media" element={<Media />} />
        <Route path="/media-detail/:id" element={<MediaDetail />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/cookie" element={<Cookie />} />
        <Route path="/iPanel/" element={<Login />} />
        <Route
          path="/iPanel/utenti-registrati"
          element={<PrivateRoute component={UtentiRegistrati} />}
        />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
