import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Header from "./partials/Header";

function UtentiRegistrati() {
  const [records, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [selectedRecord, setSelectedRecord] = useState(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get("page");
  const initialPage = parseInt(pageParam) || 1;
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [recordsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/iPanel/");
  };

  useEffect(() => {
    setCurrentPage(initialPage);
  }, [initialPage]);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    setSearchParams({ page: pageNumber });
  };

  const openModal = (record) => {
    setSelectedRecord(record);
  };

  const closeModal = () => {
    setSelectedRecord(null);
  };

  useEffect(() => {
    const fetchRecords = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const token = localStorage.getItem("token");
        if (!token) {
          navigate("/iPanel/");
          return;
        }

        const response = await fetch(
          `/api/utenti?page=${currentPage}&limit=${recordsPerPage}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(
            `Errore durante il recupero dei dati: ${response.status} ${response.statusText}`
          );
        }

        const data = await response.json();
        setRecords(data.records);
        setTotalPages(data.totalPages);
        setTotalRecords(data.totalRecords);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRecords();
  }, [currentPage, recordsPerPage, navigate]);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`}
        >
          <a
            onClick={() => paginate(i)}
            href={`/iPanel/utenti-registrati?page=${i}`}
            className={`btn btn-sm ${
              currentPage === i ? "btn-light" : "btn-dark"
            }`}
            style={{
              marginRight: "5px",
              color: "white",
              fontWeight: "500",
            }}
          >
            {i}
          </a>{" "}
        </li>
      );
    }
    return pageNumbers;
  };

  if (isLoading) {
    return <div>Caricamento in corso...</div>;
  }

  if (error) {
    return <div>Errore: {error.message}</div>;
  }

  function normalizeUrl(url) {
    if (url && !url.startsWith("http://") && !url.startsWith("https://")) {
      return "http://" + url;
    }
    return url;
  }

  return (
    <div>
      <Header />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header d-flex justify-content-between align-items-center">
                <h4 className="card-title">Lista Utenti Registrati</h4>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleLogout}
                >
                  <i class="fa-solid fa-arrow-right-from-bracket"></i> &nbsp;
                  Esci
                </button>
              </div>
              <div className="card-body">
                <table className="table">
                  <thead>
                    <tr>
                      <th style={{ fontSize: "0.8rem" }}>Nome e Cognome</th>
                      <th style={{ fontSize: "0.8rem" }}>Nazione</th>
                      <th style={{ fontSize: "0.8rem" }}>E-mail</th>
                      <th style={{ fontSize: "0.8rem" }}>Azioni</th>
                    </tr>
                  </thead>
                  <tbody>
                    {records.map((record) => (
                      <tr key={record._id}>
                        <td style={{ fontSize: "0.9rem" }}>
                          {record.nome} {record.cognome}
                        </td>
                        <td style={{ fontSize: "0.9rem" }}>{record.nazione}</td>
                        <td style={{ fontSize: "0.9rem" }}>{record.mail}</td>
                        <td>
                          <button
                            className="btn btn-sm btn-secondary"
                            onClick={() => openModal(record)}
                          >
                            <i class="fa-solid fa-circle-info"></i> &nbsp;
                            Dettagli
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <nav aria-label="Page navigation">
                  <ul className="pagination justify-content-center">
                    {currentPage > 1 && (
                      <li className="page-item">
                        <a
                          onClick={() => paginate(currentPage - 1)}
                          href={`/iPanel/utenti-registrati?page=${
                            currentPage - 1
                          }`}
                          className="btn btn-sm btn-dark"
                          style={{
                            marginRight: "5px",
                            color: "white",
                            fontWeight: "500",
                          }}
                        >
                          Precedente
                        </a>{" "}
                      </li>
                    )}
                    {renderPageNumbers()}
                    {currentPage < totalPages && (
                      <li className="page-item">
                        <a
                          onClick={() => paginate(currentPage + 1)}
                          href={`/iPanel/utenti-registrati?page=${
                            currentPage + 1
                          }`}
                          className="btn btn-sm btn-dark"
                          style={{
                            marginRight: "5px",
                            color: "white",
                            fontWeight: "500",
                          }}
                        >
                          Successiva
                        </a>{" "}
                      </li>
                    )}
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      {selectedRecord && (
        <div className="modal d-block" tabIndex="-1">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-secondary">Dettagli Utente</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeModal}
                ></button>
              </div>
              <div className="modal-body text-dark">
                <p>
                  Nome:{" "}
                  <b>
                    {selectedRecord.nome} {selectedRecord.cognome}
                  </b>
                </p>
                {selectedRecord.azienda && (
                  <p>
                    Azienda: <b>{selectedRecord.azienda}</b>
                    {selectedRecord.settore && (
                      <>
                        <br />
                        <i style={{ paddingLeft: "20px" }}>Settore</i>:{" "}
                        <b>{selectedRecord.settore}</b>
                      </>
                    )}
                    {selectedRecord.ruolo && (
                      <>
                        <br />
                        <i style={{ paddingLeft: "20px" }}>Ruolo</i>:{" "}
                        <b>{selectedRecord.ruolo}</b>
                      </>
                    )}
                  </p>
                )}
                {selectedRecord.nazione && (
                  <p>
                    Nazione: <b>{selectedRecord.nazione}</b>
                  </p>
                )}
                <p>
                  E-mail: <b>{selectedRecord.mail}</b>
                </p>
                {selectedRecord.tel && (
                  <p>
                    Telefono: <b>{selectedRecord.tel}</b>
                  </p>
                )}
                {selectedRecord.web && (
                  <p>
                    Sito web:{" "}
                    <a
                      href={normalizeUrl(selectedRecord.web)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {selectedRecord.web}
                    </a>
                  </p>
                )}
                <p>
                  Settori di interesse:{" "}
                  {Array.isArray(selectedRecord.interessi) &&
                    selectedRecord.interessi.map((interesse, index) => (
                      <span key={index} style={{ fontWeight: "bold" }}>
                        {interesse}
                        {index < selectedRecord.interessi.length - 1 && ", "}
                      </span>
                    ))}
                </p>
                {selectedRecord.descrizione && (
                  <p>
                    Note: <b>{selectedRecord.descrizione}</b>
                  </p>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={closeModal}
                >
                  Chiudi
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default UtentiRegistrati;
