import React from "react";

const Header = () => {
  return (
    <nav className="navbar navbar-light justify-content-md-center">
      <span className="navbar-brand">
        <img
          src="/img/logo-meetnewpartners-com.png"
          style={{ width: "33vh", height: "auto" }}
          alt="MeetNewPartners.com"
        />
      </span>
    </nav>
  );
};

export default Header;