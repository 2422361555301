import React from "react";
import NavBar from "./partials/NavBar";
const About = () => {
  return (
    <>
      <NavBar />
      <div className="container mt-3">
        <div className="row">
          <div className="col-12 mt-3 mb-3 p-3">
            <div className="card">
              <img
                src="/img/about-us.jpg"
                className="card-img-top"
                alt="Privacy policy"
              ></img>
              <div className="card-body">
                <h1>About us</h1>
                <p>
                  Meet New Partners emerges from a shared vision of Umbrian
                  professionals and entrepreneurs,{" "}
                  <b>
                    united by deep territorial roots and extensive experience in
                    professional networking
                  </b>
                  . Our strength lies in our ability to connect excellence and
                  create opportunities that transcend regional and national
                  boundaries.
                </p>
                <p>
                  We've built strong relationships based on trust and shared
                  goals, creating an ecosystem of professionals and businesses
                  that represent the finest of Umbria and Italy.
                </p>
                <h4>Our mission and core values</h4>
                <p>
                  We pursue a dual mission: strengthening connections among
                  regional excellences while projecting ourselves onto the
                  international stage, bringing the best of Umbria and Italy to
                  the world.
                </p>
                <p>Meet New Partners distinguishes itself through:</p>
                <p>
                  <b>Quality Networking</b>
                  <br />
                  We strive to build relationships that are as efficient and as
                  meaningful as possible
                </p>
                <p>
                  <b>Regional Excellence</b>
                  <br />
                  We carefully select and promote only the finest expressions of
                  our territory, ensuring quality and authenticity across all
                  sectors.
                </p>
                <p>
                  <b>Innovation Meets Tradition</b>
                  <br />
                  We enhance Umbria's historical and cultural heritage by
                  projecting it into the future through innovative and
                  sustainable approaches.
                </p>
                <h4>Looking Forward</h4>
                <p>
                  Our participation in the Global Convention in Hawaii marks the
                  beginning of our journey to position Umbria and its excellence
                  in the global context. We aim to create a permanent bridge
                  between Umbria and international markets, facilitating
                  commercial, cultural, and professional exchanges that enrich
                  all parties involved.
                </p>
                <p>
                  Join us in this exciting journey of connecting Umbrian
                  excellence with global opportunities.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
